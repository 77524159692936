import React from "react";
import { countdownPositionInputs } from "../../constants/stylingModal";
import {
  TextField,
  Box,
  Typography,
  Tooltip
} from "../../materialUi/components";
import { InfoIcon } from "../../materialUi/icons";

const CountdownStylingControls = ({
  styles,
  handleSpacingChange,
  positionStyle,
  CommonStyle
}) => {
  const updateStyles = (name, value) => {
    if (!Number.isInteger(value)) {
      return;
    }
    if (value > 100) value = 100;
    else if (value < 0) value = 0;

    handleSpacingChange({
      ...styles,
      [name]: value
    });
  };

  return (
    <Box sx={{ ...CommonStyle }}>
      <Box sx={{ ...positionStyle }}>
        <Typography
          sx={{ width: "100%", marginBottom: "10px", display: "flex" }}
        >
          Countdown Position (%)
          <Tooltip
            title="Width is now static.Mobile:33%,Tablet:25%"
            placement="top"
            arrow
          >
            <InfoIcon fontSize="small" sx={{ marginLeft: "2px" }} />
          </Tooltip>
        </Typography>
        <Box sx={{ width: "100%", display: "flex" }}>
          {countdownPositionInputs.map(({ name, label }, idx) => (
            <TextField
              type="Number"
              key={idx}
              label={label}
              name={name}
              placeholder={label}
              value={styles[name]}
              onChange={e => updateStyles(name, +e.target.value)}
              size="small"
              sx={{
                width: `${Math.floor(100 / countdownPositionInputs.length)}%`,
                marginRight: "5px"
              }}
              InputLabelProps={{ shrink: true }}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default CountdownStylingControls;
