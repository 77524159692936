import qs from "query-string";
import {
  URL_LIST,
  DELETE_URL,
  UPDATE_TOGGLE_URL_DATA,
  UPDATE_URL,
  VALIDATE_URL,
  FILTER_CONDITION_COUNT,
  SET_URL_PAGE_DATA,
  UPDATE_ACTIVE_URL_PAGE_DATA,
  RESET_URL_PAGE_DATA,
  URL_PAGE_OPEN_COMPONENT,
  URL_PAGE_TOGGLE_COMPONENT,
  SET_URL_ROLLBACK_HISTORY,
} from "../constants/actions";
import { ENV_CONFIG } from "../config/env";
import {
  hideLoadingIndicator,
  showLoadingIndicator,
  snackbar,
} from "./common.action";
import {
  deleteWebApi,
  fetchWebApi,
  postDashboardWebApi,
  putDashboardWebApi
} from "../webapis/apiResource";
import { getAccessToken } from "../util/storeHelper";
import { handleFetchError } from "../util/errorHandler";
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from "../constants";

const listUrlData = (data, count) => ({ type: URL_LIST, data, count });

export const fetchUrlData = (limit = 50, skip = 0, searchText = "") => {
  let apiURL = `${ENV_CONFIG.baseURL}${ENV_CONFIG.versionInfo}/url/list?limit=${limit}&skip=${skip}&search=${searchText}`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return fetchWebApi(getAccessToken(getState), apiURL)
      .then(response => {
        if (response.data.code === 200) {
          dispatch(listUrlData(response.data.data, response.data.count));
        }
        dispatch(hideLoadingIndicator());
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const addUrlData = payload => {
  let apiURL = `${ENV_CONFIG.baseURL}${ENV_CONFIG.versionInfo}/url/create`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return postDashboardWebApi(getAccessToken(getState), apiURL, payload)
      .then(response => {
        if (response.data.code === 200) {
          dispatch(snackbar({
            open: true, severity: "success",
            text: SUCCESS_MESSAGES["urlAdded"]
          }));
        } else {
          dispatch(snackbar({
            open: true, severity: "error",
            text: (response.data.error && response.data.error.detail) ||
              (response.data.error && response.data.error.message) ||
              ERROR_MESSAGES["userAdd"]
          }));
        }
        dispatch(hideLoadingIndicator());
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        dispatch(snackbar({
          open: true, severity: "error",
          text: ERROR_MESSAGES["urlAdd"]
        }));
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

const removeUrlData = data => ({ type: DELETE_URL, ...data });

export const deleteUrlData = (id, url, index) => {
  let apiURL = `${ENV_CONFIG.baseURL}${ENV_CONFIG.versionInfo}/url/${id}?url=${url}`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return deleteWebApi(getAccessToken(getState), apiURL)
      .then(response => {
        if (response.data.code === 200) {
          dispatch(removeUrlData({ id, url, index }));
          dispatch(snackbar({
            open: true, severity: "success",
            text: SUCCESS_MESSAGES["urlDeleted"]
          }));
        } else {
          dispatch(snackbar({
            open: true, severity: "error",
            text: (response.data.error && response.data.error.detail) ||
              (response.data.error && response.data.error.message) ||
              ERROR_MESSAGES["userDelete"]
          }));
        }
        dispatch(hideLoadingIndicator());
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        dispatch(snackbar({
          open: true, severity: "error",
          text: ERROR_MESSAGES["urlDelete"]
        }));
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

const modifyToggleData = data => ({ type: UPDATE_TOGGLE_URL_DATA, ...data });

export const updateToggleData = (id, payload, index) => {
  let apiURL = `${ENV_CONFIG.baseURL}${ENV_CONFIG.versionInfo}/url/change-status/${id}`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return putDashboardWebApi(getAccessToken(getState), apiURL, payload)
      .then(response => {
        if (response.data.code === 200) {
          dispatch(modifyToggleData({ id, enabled: payload.enabled, index }));
          dispatch(snackbar({
            open: true, severity: "success",
            text: payload.enabled === true
              ? SUCCESS_MESSAGES["urlToggleUpdated"]
              : SUCCESS_MESSAGES["urlToggleDisabled"]
          }));
        } else {
          dispatch(snackbar({
            open: true, severity: "error",
            text: (response.data.error && response.data.error.detail) ||
              (response.data.error && response.data.error.message) ||
              ERROR_MESSAGES["urlUpdate"]
          }));
        }
        dispatch(hideLoadingIndicator());
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        dispatch(snackbar({
          open: true, severity: "error",
          text: ERROR_MESSAGES["urlUpdate"]
        }));
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};


const modifyUrlData = data => ({ type: UPDATE_URL, data });

export const updateUrlData = (id, payload) => {
  let apiURL = `${ENV_CONFIG.baseURL}${ENV_CONFIG.versionInfo}/url/${id}`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return putDashboardWebApi(getAccessToken(getState), apiURL, payload)
      .then(response => {
        if (response.data.code === 200) {
          dispatch(modifyUrlData(response.data.data));
          dispatch(snackbar({
            open: true, severity: "success",
            text: SUCCESS_MESSAGES["urlDataUpdated"]
          }));
        } else {
          dispatch(snackbar({
            open: true, severity: "error",
            text: (response.data.error && response.data.error.detail) ||
              (response.data.error && response.data.error.message) ||
              ERROR_MESSAGES["urlDataUpdate"]
          }));
        }
        dispatch(hideLoadingIndicator());
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        dispatch(snackbar({
          open: true, severity: "error",
          text: ERROR_MESSAGES["urlDataUpdate"]
        }));
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

const validateData = data => ({ type: VALIDATE_URL, data });

export const validateUrlData = payload => {
  let apiURL = `${ENV_CONFIG.baseURL}${ENV_CONFIG.versionInfo}/url/validate`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return postDashboardWebApi(getAccessToken(getState), apiURL, payload)
      .then(response => {
        if (response.data.code === 200) {
          dispatch(validateData(response.data.data));
        }
        dispatch(hideLoadingIndicator());
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

const filterConditionCount = data => ({ type: FILTER_CONDITION_COUNT, data });

export const filterConditionProductCount = payload => {
  let apiURL = `${ENV_CONFIG.productBaseURLV5}${ENV_CONFIG.versionInfo}/products/get-product-count-info-by-query`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return postDashboardWebApi(getAccessToken(getState), apiURL, payload)
      .then(response => {
        dispatch(filterConditionCount(response.data.data));
        dispatch(hideLoadingIndicator());
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const setUrlPage = data => ({ type: SET_URL_PAGE_DATA, data });

export const updateActiveUrlPage = data => ({ type: UPDATE_ACTIVE_URL_PAGE_DATA, data });

export const resetUrlPage = () => ({ type: RESET_URL_PAGE_DATA });

export const openComponent = ({ id, index }) => ({
  type: URL_PAGE_OPEN_COMPONENT,
  id,
  index
});

export const toggleSection = ({ index }) => ({
  type: URL_PAGE_TOGGLE_COMPONENT,
  index
});

export const setRollbackHistory = history => ({
  type: SET_URL_ROLLBACK_HISTORY,
  payload: history
});

export const getAllRollbackHistory = (id, fromDate, toDate) => (dispatch, getState) => {

  dispatch(showLoadingIndicator());

  return postDashboardWebApi(
    getAccessToken(getState),
    qs.stringifyUrl({
      url: `${ENV_CONFIG.configBaseURL}/history/urls/${id}`
    }),
    { fromDate, toDate }
  )
    .then(response => {
      dispatch(setRollbackHistory(response.data));
      return response;
    })
    .catch(error => {
      handleFetchError(error, dispatch);
      return error.response;
    })
    .finally(() => {
      dispatch(hideLoadingIndicator());
    });
};

const manageRequestPromiseRollback = (requestPromise, dispatch, activityType, index) =>
  requestPromise
    .then(response => {
      const { data } = response;
      if (data.code === 200) {
        dispatch(setUrlPage(data.data));
        const { _id, enabled } = data.data || {};
        dispatch(
          modifyToggleData({ id: _id, enabled, index })
        );
        dispatch(snackbar({
          open: true, severity: "success",
          text: "Rollback successful"
        }));
      } else {
        dispatch(snackbar({
          open: true, severity: "error",
          text: response.data.error.message ||
            response.data.error.detail ||
            ERROR_MESSAGES[activityType]
        }));
      }
      return response;
    })
    .catch(error => {
      handleFetchError(error, dispatch);
      return error.response;
    })
    .finally(() => {
      dispatch(hideLoadingIndicator());
    });

export const setPageRollback = (id, index) => (dispatch, getState) => {
  const state = getState();
  dispatch(showLoadingIndicator());
  return manageRequestPromiseRollback(
    postDashboardWebApi(
      getAccessToken(getState),
      qs.stringifyUrl({
        url: `${ENV_CONFIG.configBaseURL}/history/urls/rollback`
      }),
      { versionId: id }
    ),
    dispatch, "", index
  );
};

export const getRollbackCompareVersionData = (id, versions) => (dispatch, getState) => {
  dispatch(showLoadingIndicator());
  return postDashboardWebApi(
    getAccessToken(getState),
    qs.stringifyUrl({
      url: `${ENV_CONFIG.configBaseURL}/history/urls/content?referenceId=${id}`
    }),
    { versions }
  )
    .then(response => {
      if (!response || response.status !== 200 ||
        !response.data || !response.data.data ||
        response.data.data.length < 2) {
        dispatch(snackbar({ open: true, severity: "error", text: "Error while fetching versions Info " }));
        return []
      } else {
        return response.data.data
      }
    })
    .catch(error => {
      handleFetchError(error, dispatch);
      return error.response;
    })
    .finally(() => {
      dispatch(hideLoadingIndicator());
    });
};

