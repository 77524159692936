import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import reactImageSize from "react-image-size";
import get from "lodash/get";
import set from "lodash/set";
import { cloneDeep } from "lodash";
import {
  SLIDER_IMAGE_ACCORDION_PLACEHOLDERS,
  deviceCheckboxes,
  deviceTypes,
  bannerTypeValueConstants,
  imageWidthOptions,
  imageErrors,
  imageModalAttributes,
  imageFormatArr,
  IMAGE_SIZE_AND_TYPE_TOOLTIP,
  dynamicBannerItemsTypes
} from "../../../constants";

import {
  Button,
  Box,
  TextField,
  MenuItem,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  IconButton,
  BasicPopover,
  Slider,
  Typography,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Autocomplete
} from "../../../materialUi/components";
import { ColorPicker } from "../../ColorPicker";
import { DeleteIcon, InfoIcon } from "../../../materialUi/icons";
import { UploadImageModal } from "./UploadImageModal";
import SpacingControls from "../SpacingControlsMUI";
import CountdownStylingControls from "../CountdownStylingControls";
import ImageSelectionModal from "../../../containers/modal/imageSelectionModalMUI";
import { bannerImageSchema } from "../../../util/homepage";
import { toastMsg } from "../../../actions/common.action";
import {
  putBannerDataStyles,
  changeBannerImageHoverStyle,
  updateBannerRowItemData,
  fetchRedirectionUrlList
} from "../../../actions/dynamicBanner.action";

export const BannerImageModal = ({
  isOpen,
  handleClose,
  handleSetModalOpen,
  image,
  bannerType,
  deviceType,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation,
  path
}) => {
  const dispatch = useDispatch();
  const multiLanguageConfig = useSelector(
    state => state.common.multiLanguageConfig
  );
  const [imageData, setImageData] = useState(cloneDeep(image));
  const styles = get(imageData, "styles", {});
  const attributes = get(imageData, "attributes", []);
  const [imageSelectionModal, setImageSelectionModal] = useState(false);
  const [language, setLanguage] = useState(null);
  const [attributeList, setAttributeList] = useState([]);
  const [selectedAttributeValue, setSelectedAttributeValue] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [attributeRowIndex, setAttributeRowIndex] = useState(-1);
  const [redirectionUrlOptions, setRedirectionUrlOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [displayColorPickerById, setDisplayColorPickerById] = useState({});

  useEffect(() => {
    setImageData(cloneDeep(image));
  }, [image]);

  useMemo(() => {
    const list = imageModalAttributes.map(item => {
      const disabled = attributes.some(({ key }) => key === item.value);
      return { ...item, disabled };
    });
    const selectedAttributeIndex = list.findIndex(
      item => item.disabled === false
    );
    const selectedValue =
      selectedAttributeIndex > -1 ? list[selectedAttributeIndex].value : "";
    setSelectedAttributeValue(selectedValue);
    setAttributeList(list);
  }, []);

  useMemo(() => {
    const list = imageModalAttributes.map(item => {
      const disabled = attributes.some(({ key }) => key === item.value);
      return { ...item, disabled };
    });
    const selectedAttributeIndex = list.findIndex(
      item => item.disabled === false
    );
    const selectedValue =
      selectedAttributeIndex > -1 ? list[selectedAttributeIndex].value : "";
    setSelectedAttributeValue(selectedValue);
    setAttributeList(list);
  }, [attributes.length]);

  const updateRowImageData = ({ name, value, language }) => {
    const _imageData = cloneDeep(imageData);
    set(_imageData, language ? [language, name] : [name], value);
    setImageData(_imageData);
  };

  const addAttributeRowImageData = index => {
    const _imageData = cloneDeep(imageData);
    const value = { key: selectedAttributeValue, value: "" };
    set(_imageData, ["attributes", index], value);
    setImageData(_imageData);
  };

  const updateAttributeRowImageData = (index, name, value) => {
    const _imageData = cloneDeep(imageData);
    set(_imageData, ["attributes", index, name], value);
    setImageData(_imageData);
  };

  const removeAttributeRowImageData = index => {
    const newAttributeList = cloneDeep(attributes);
    newAttributeList.splice(index, 1);
    const _imageData = cloneDeep(imageData);
    set(_imageData, ["attributes"], newAttributeList);
    setImageData(_imageData);
  };

  const handleImageUploadSuccess = (
    keyName,
    cdnURL,
    brandId,
    imageLanguage,
    modalName,
    fileName
  ) => {
    reactImageSize(cdnURL)
      .then(({ width, height }) => {
        if (width > imageWidthOptions[deviceType].max) {
          throw new Error(imageErrors[deviceType]);
        }
        const _imageData = cloneDeep(imageData);
        set(_imageData, [imageLanguage, "imageURL"], cdnURL);
        set(_imageData, [imageLanguage, "ratio"], (height / width) * 100);
        set(_imageData, [imageLanguage, "width"], width);
        if (fileName) {
          set(_imageData, ["bannerTitle"], fileName);
        }
        setImageData(_imageData);
        setImageSelectionModal(false);
        setLanguage(null);
      })
      .catch(errorMessage => dispatch(toastMsg("error", errorMessage.message)));
  };

  const deleteImage = imageLanguage => {
    const _imageData = cloneDeep(imageData);
    set(_imageData, [imageLanguage, "imageURL"], "");
    setImageData(_imageData);
  };

  const handeImageSelectionModal = language => {
    setLanguage(language);
    setImageSelectionModal(!imageSelectionModal);
  };

  const handleChangeEnabled = ({ name, value, language }) => {
    const enabledOn = get(imageData, `${language}.enabledOn`, []);

    enabledOn.indexOf(name) === -1 && value
      ? enabledOn.push(name)
      : enabledOn.splice(enabledOn.indexOf(name), 1);

    updateRowImageData({
      name: "enabledOn",
      value: enabledOn,
      language
    });
  };

  const showDeviceCheckboxes = useMemo(
    () => deviceType !== deviceTypes.desktop,
    [deviceType]
  );

  const validateImageModalBlock = () => {
    bannerImageSchema(
      !showDeviceCheckboxes,
      multiLanguageConfig[deviceType]["isArabicEnabled"]
    )
      .validate(imageData)
      .then(() => {
        dispatch(toastMsg("success", "Successfully validated"));

        updateRowImageData({
          name: "validated",
          value: true
        });

        dispatch(
          updateBannerRowItemData({
            path,
            value: cloneDeep(imageData),
            pageType,
            updateCatalogPageType,
            updateCatalogPageLocation
          })
        );
        handleSetModalOpen(false);
      })
      .catch(err => {
        dispatch(toastMsg("warning", err.errors.join(",")));
      });
  };

  const handleSpacingChange = value => {
    const _imageData = cloneDeep(imageData);
    set(_imageData, ["styles"], value);
    setImageData(_imageData);
  };

  const handleChangeHoverStyles = (key, value, maxLength) => {
    if (value > maxLength) value = maxLength;
    else if (value < 0) value = 0;
    const _imageData = cloneDeep(imageData);
    value = +Number(value).toFixed(3);
    set(_imageData, ["styles", "hoverValues", key], value);
    let formattedValue;

    switch (key) {
      case "transform": {
        formattedValue = `scale(${value})`;
        break;
      }
      case "transition": {
        formattedValue = `transform ${value}s`;
        break;
      }

      default: {
        formattedValue = value;
      }
    }

    set(_imageData, ["styles", "hover", key], formattedValue);
    setImageData(_imageData);
  };

  const handleChangeAttributeType = event => {
    setSelectedAttributeValue(event.target.value || "");
  };

  const handleAddAttributeRow = () => {
    addAttributeRowImageData(attributes.length);
  };

  const handleUpdateAttributeRow = (index, name, value) => {
    updateAttributeRowImageData(index, name, value);
  };

  const handleRemoveAttributeRow = index => {
    removeAttributeRowImageData(index);
  };

  const getRedirectionUrlList = async text => {
    setLoading(true);
    const response = await dispatch(fetchRedirectionUrlList(10, 0, text));
    let newList = response?.data?.data || [];
    newList = newList.map(({ url }) => url);
    setRedirectionUrlOptions([...new Set(newList)]);
    setLoading(false);
  };

  const handleModalClose = () => {
    setImageData(cloneDeep(image));
    handleClose();
  };

  const handlePickerClickById = id =>
    setDisplayColorPickerById(prevState => ({
      ...prevState,
      [id]: !prevState[id]
    }));

  const opacity = get(styles, "hoverValues.opacity", 0);
  const transform = get(styles, "hoverValues.transform", 0);
  const transition = get(styles, "hoverValues.transition", 0);

  return (
    <div className="banner-image-modal">
      {imageSelectionModal && (
        <ImageSelectionModal
          title="Upload Image"
          modalName={"imageSelectionModal"}
          keyName="modal"
          language={language}
          containerName={"dynamicBanner"}
          onHide={() => setImageSelectionModal(false)}
          onSuccess={handleImageUploadSuccess}
          isOpen={imageSelectionModal}
          handleClose={() => setImageSelectionModal(false)}
          imageFormatArr={imageFormatArr}
          disableCDNuploadOption={false}
        />
      )}
      {Boolean(anchorEl) && (
        <BasicPopover
          open={Boolean(anchorEl)}
          id={attributeRowIndex}
          anchorEl={anchorEl}
          handleCancel={() => {
            setAnchorEl(null);
            setAttributeRowIndex(-1);
          }}
          handleSave={() => {
            handleRemoveAttributeRow(attributeRowIndex);
            setAnchorEl(null);
            setAttributeRowIndex(-1);
          }}
          text={"Are you sure you want to delete the row?"}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "right"
          }}
        />
      )}
      <Dialog
        open={isOpen}
        onClose={handleModalClose}
        maxWidth={"lg"}
        fullWidth
      >
        <DialogTitle>{"Image"}</DialogTitle>
        <DialogContent dividers>
          <Box>
            <Box>
              <div className="banner-wrapper">
                <div className="banner-english-wrapper">
                  <Autocomplete
                    inputValue={get(imageData, `english.redirectionLink`, "")}
                    onInputChange={(e, newInputValue) => {
                      updateRowImageData({
                        name: "redirectionLink",
                        value: newInputValue || "",
                        language: "english"
                      });
                      if (
                        newInputValue?.length &&
                        newInputValue.length % 3 === 0
                      )
                        getRedirectionUrlList(newInputValue);
                    }}
                    loading={loading}
                    freeSolo
                    options={redirectionUrlOptions}
                    sx={{ width: "100%" }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Redirection Link(must start with '/')"
                        size="small"
                        required
                        lang="english"
                        InputLabelProps={{ shrink: true }}
                        placeholder={
                          SLIDER_IMAGE_ACCORDION_PLACEHOLDERS.english
                            .redirectionLink
                        }
                      />
                    )}
                    onBlur={() => {
                      setRedirectionUrlOptions([]);
                      setLoading(false);
                    }}
                  />
                  {showDeviceCheckboxes && (
                    <Box>
                      {deviceCheckboxes.map(({ name, label }) => {
                        const enabledOn = get(
                          imageData,
                          `english.enabledOn`,
                          []
                        );
                        return (
                          <FormControlLabel
                            control={
                              <Checkbox
                                key={name}
                                name={name}
                                checked={enabledOn.includes(name)}
                                onChange={e =>
                                  handleChangeEnabled({
                                    name: e.target.name,
                                    value: e.target.checked,
                                    language: "english"
                                  })
                                }
                              />
                            }
                            label={label}
                          />
                        );
                      })}
                    </Box>
                  )}
                  <UploadImageModal
                    imageURL={get(imageData, `english.imageURL`, "")}
                    language="english"
                    handeImageSelectionModal={() =>
                      handeImageSelectionModal("english")
                    }
                    deleteImage={deleteImage}
                    infoIconTitle={IMAGE_SIZE_AND_TYPE_TOOLTIP}
                  />
                  {imageData.type === dynamicBannerItemsTypes.slide && (
                    <Box
                      className="mui-countdown"
                      style={{ marginTop: "10px" }}
                    >
                      <span>Header BackgroundColor</span>
                      <span
                        onClick={() =>
                          updateRowImageData({
                            name: "headerBackgroundColor",
                            value: "",
                            language: "english"
                          })
                        }
                        className="countdown-delete-icon"
                      />
                      <Box sx={{ marginTop: "10px" }}>
                        <ColorPicker
                          id="en-headerBackgroundColor"
                          onClick={() =>
                            handlePickerClickById("en-headerBackgroundColor")
                          }
                          background={get(
                            imageData,
                            "english.headerBackgroundColor",
                            "#ffffff"
                          )}
                          displayPicker={
                            !!displayColorPickerById["en-headerBackgroundColor"]
                          }
                          onChange={changedColor =>
                            updateRowImageData({
                              name: "headerBackgroundColor",
                              value: changedColor.hex,
                              language: "english"
                            })
                          }
                        />
                      </Box>
                    </Box>
                  )}
                </div>
                <div className="banner-arabic-wrapper">
                  <Autocomplete
                    inputValue={get(imageData, `arabic.redirectionLink`, "")}
                    onInputChange={(e, newInputValue) => {
                      updateRowImageData({
                        name: "redirectionLink",
                        value: newInputValue || "",
                        language: "arabic"
                      });
                      if (
                        newInputValue?.length &&
                        newInputValue.length % 3 === 0
                      )
                        getRedirectionUrlList(newInputValue);
                    }}
                    loading={loading}
                    freeSolo
                    options={redirectionUrlOptions}
                    sx={{ width: "100%" }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Redirection Link(must start with '/')"
                        size="small"
                        required
                        lang="arabic"
                        InputLabelProps={{ shrink: true }}
                        placeholder={
                          SLIDER_IMAGE_ACCORDION_PLACEHOLDERS.arabic
                            .redirectionLink
                        }
                      />
                    )}
                    onBlur={() => {
                      setRedirectionUrlOptions([]);
                      setLoading(false);
                    }}
                  />
                  {showDeviceCheckboxes && (
                    <Box>
                      {deviceCheckboxes.map(({ name, label }) => {
                        const enabledOn = get(
                          imageData,
                          `arabic.enabledOn`,
                          []
                        );
                        return (
                          <FormControlLabel
                            control={
                              <Checkbox
                                key={name}
                                name={name}
                                checked={enabledOn.includes(name)}
                                onChange={e =>
                                  handleChangeEnabled({
                                    name: e.target.name,
                                    value: e.target.checked,
                                    language: "arabic"
                                  })
                                }
                              />
                            }
                            label={label}
                          />
                        );
                      })}
                    </Box>
                  )}
                  <UploadImageModal
                    imageURL={get(imageData, `arabic.imageURL`, "")}
                    language="arabic"
                    handeImageSelectionModal={() =>
                      handeImageSelectionModal("arabic")
                    }
                    deleteImage={deleteImage}
                    infoIconTitle={IMAGE_SIZE_AND_TYPE_TOOLTIP}
                  />
                  {imageData.type === dynamicBannerItemsTypes.slide && (
                    <Box
                      className="mui-countdown"
                      style={{ marginTop: "10px" }}
                    >
                      <span>Header BackgroundColor</span>
                      <span
                        onClick={() =>
                          updateRowImageData({
                            name: "headerBackgroundColor",
                            value: "",
                            language: "arabic"
                          })
                        }
                        className="countdown-delete-icon"
                      />
                      <Box sx={{ marginTop: "10px" }}>
                        <ColorPicker
                          id="ar-headerBackgroundColor"
                          onClick={() =>
                            handlePickerClickById("ar-headerBackgroundColor")
                          }
                          background={get(
                            imageData,
                            "arabic.headerBackgroundColor",
                            "#ffffff"
                          )}
                          displayPicker={
                            !!displayColorPickerById["ar-headerBackgroundColor"]
                          }
                          onChange={changedColor =>
                            updateRowImageData({
                              name: "headerBackgroundColor",
                              value: changedColor.hex,
                              language: "arabic"
                            })
                          }
                        />
                      </Box>
                    </Box>
                  )}
                </div>
              </div>
            </Box>
            <Divider sx={{ marginTop: "10px", marginBottom: "10px" }} />
            <Box sx={{ display: "flex" }}>
              <Box sx={{ width: "50%", paddingRight: "25px" }}>
                <Box sx={{ marginBottom: "10px" }}>
                  <TextField
                    type="text"
                    name={"bannerId"}
                    value={imageData.bannerId || ""}
                    onChange={e =>
                      updateRowImageData({
                        name: "bannerId",
                        value: e.target.value || ""
                      })
                    }
                    size="small"
                    variant="outlined"
                    label={"Banner Id"}
                    placeholder="Please Enter Banner Id"
                    InputLabelProps={{ shrink: true }}
                    required
                    sx={{ width: "49%" }}
                  />
                  <TextField
                    type="text"
                    name={"bannerTitle"}
                    value={imageData.bannerTitle || ""}
                    label={"Banner Title"}
                    placeholder="Please Enter Banner Title"
                    onChange={e =>
                      updateRowImageData({
                        name: "bannerTitle",
                        value: e.target.value || ""
                      })
                    }
                    size="small"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    required
                    sx={{ width: "49%", float: "right" }}
                  />
                </Box>
                {bannerType !== bannerTypeValueConstants.SLIDER && (
                  <SpacingControls
                    styles={styles}
                    handleSpacingChange={handleSpacingChange}
                    CommonStyle={{ display: "flex", width: "100%" }}
                    paddingStyle={{ width: "70%" }}
                    marginStyle={{ width: "30%", marginLeft: "20px" }}
                  />
                )}
                {deviceType === deviceTypes.desktop &&
                  bannerType !== bannerTypeValueConstants.SLIDER && (
                    <Box sx={{ marginTop: "10px" }}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography variant="h6">Hover</Typography>
                        <Tooltip
                          title={`Ideal values for Hover effect:
                        opacity: 1, transform: 1, transition: 0`}
                          placement="top"
                          arrow
                        >
                          <InfoIcon
                            fontSize="small"
                            sx={{ marginLeft: "2px" }}
                          />
                        </Tooltip>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "10px"
                        }}
                      >
                        <Typography sx={{ width: "10%" }}>Opacity</Typography>
                        <Slider
                          value={opacity}
                          max={1}
                          min={0}
                          step={0.1}
                          onChange={(_, value) =>
                            handleChangeHoverStyles("opacity", value, 1)
                          }
                          size="medium"
                          sx={{
                            marginLeft: "10px",
                            marginRight: "10px",
                            width: "80%"
                          }}
                        />
                        <TextField
                          type="Number"
                          name={"opacity"}
                          value={opacity}
                          onChange={e =>
                            handleChangeHoverStyles(
                              "opacity",
                              e.target.value,
                              1
                            )
                          }
                          size="small"
                          sx={{ width: "10%" }}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "10px"
                        }}
                      >
                        <Typography sx={{ width: "10%" }}>Transform</Typography>
                        <Slider
                          value={transform}
                          max={2}
                          min={0}
                          step={0.025}
                          onChange={(_, value) =>
                            handleChangeHoverStyles("transform", value, 2)
                          }
                          sx={{
                            marginLeft: "10px",
                            marginRight: "10px",
                            width: "80%"
                          }}
                        />
                        <TextField
                          type="Number"
                          name={"transform"}
                          value={transform}
                          onChange={e =>
                            handleChangeHoverStyles(
                              "transform",
                              e.target.value,
                              2
                            )
                          }
                          size="small"
                          sx={{ width: "10%" }}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "10px"
                        }}
                      >
                        <Typography sx={{ width: "10%" }}>
                          Transition
                        </Typography>
                        <Slider
                          value={transition}
                          max={2}
                          min={0}
                          step={0.1}
                          onChange={(_, value) =>
                            handleChangeHoverStyles("transition", value, 2)
                          }
                          sx={{
                            marginLeft: "10px",
                            marginRight: "10px",
                            width: "80%"
                          }}
                        />
                        <TextField
                          type="Number"
                          name={"transition"}
                          value={transition}
                          onChange={e =>
                            handleChangeHoverStyles(
                              "transition",
                              e.target.value,
                              2
                            )
                          }
                          size="small"
                          sx={{ width: "10%" }}
                        />
                      </Box>
                    </Box>
                  )}
              </Box>
              <Box
                sx={{
                  width: "50%",
                  paddingLeft: "25px",
                  paddingRight: "25px",
                  border: "1px solid rgba(0, 0, 0, 0.12)"
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      marginTop: "32px",
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    <TextField
                      select
                      label="Add Additional Attributes"
                      onChange={handleChangeAttributeType}
                      placeholder="Please Select Attribute type"
                      size="small"
                      sx={{
                        width: "50%",
                        marginLeft: "auto",
                        marginRight: "10px"
                      }}
                      value={selectedAttributeValue}
                      InputLabelProps={{ shrink: true }}
                    >
                      {attributeList.map(option => (
                        <MenuItem
                          key={option.value}
                          value={option.value}
                          disabled={option.disabled}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <Button
                      variant="outlined"
                      onClick={handleAddAttributeRow}
                      size="small"
                      disabled={
                        attributes.length === imageModalAttributes.length
                      }
                    >
                      Add
                    </Button>
                  </Box>
                </Box>
                <Box
                  sx={{ width: "100%", overflow: "hidden", marginTop: "42px" }}
                >
                  <TableContainer sx={{ maxHeight: 440 }} component={Paper}>
                    <Table aria-label="a dense table" size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            key={"key"}
                            align={"center"}
                            style={{ backgroundColor: "#f1f1f1" }}
                          >
                            {"Key"}
                          </TableCell>
                          <TableCell
                            key={"value"}
                            align={"center"}
                            style={{ backgroundColor: "#f1f1f1" }}
                          >
                            {"Value"}
                          </TableCell>
                          <TableCell
                            key={"action"}
                            align={"center"}
                            style={{ backgroundColor: "#f1f1f1" }}
                          >
                            {"Action"}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {attributes.map(({ key, value }, index) => (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={index}
                            style={{ maxHeight: "15px" }}
                          >
                            <TableCell key={"key"} align={"left"} width={"30%"}>
                              <TextField
                                value={key}
                                style={{ width: "100%" }}
                                size="small"
                                disabled
                              />
                            </TableCell>
                            <TableCell
                              key={"value"}
                              align={"left"}
                              width={"60%"}
                            >
                              <TextField
                                onChange={ev =>
                                  handleUpdateAttributeRow(
                                    index,
                                    "value",
                                    get(ev, "target.value", null)
                                  )
                                }
                                value={value}
                                style={{ width: "100%" }}
                                size="small"
                              />
                            </TableCell>
                            <TableCell
                              key={"action"}
                              align={"center"}
                              width={"10%"}
                            >
                              <Tooltip title="Delete" placement="top" arrow>
                                <IconButton
                                  aria-label="delete"
                                  onClick={ev => {
                                    setAnchorEl(ev.currentTarget);
                                    setAttributeRowIndex(index);
                                  }}
                                  size="small"
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleModalClose}
            variant="outlined"
            sx={{ marginRight: "auto" }}
          >
            Close
          </Button>
          <Button onClick={validateImageModalBlock} variant="outlined">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export const BannerImage = ({
  image,
  bannerType,
  deviceType,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation,
  path,
  isDeviceCheckboxesRequired,
  handleBannerImageData,
  showCountdownStylingControls
}) => {
  const dispatch = useDispatch();

  const styles = get(image, "styles", {});

  const [imageSelectionModal, setImageSelectionModal] = useState(false);
  const [language, setLanguage] = useState(null);
  const [redirectionUrlOptions, setRedirectionUrlOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const updateRowImageData = ({ name, value, language }) => {
    const path = language ? [language, name] : [name];
    handleBannerImageData(value, path);
  };

  const handleImageUploadSuccess = (
    keyName,
    cdnURL,
    brandId,
    imageLanguage,
    modalName,
    fileName
  ) => {
    reactImageSize(cdnURL)
      .then(({ width, height }) => {
        if (width > imageWidthOptions[deviceType].max) {
          throw new Error(imageErrors[deviceType]);
        }

        const _imageData = cloneDeep(image);
        set(_imageData, [imageLanguage, "imageURL"], cdnURL);
        set(_imageData, [imageLanguage, "ratio"], (height / width) * 100);
        set(_imageData, [imageLanguage, "width"], width);

        if (fileName) {
          set(_imageData, ["bannerTitle"], fileName);
        }
        handleBannerImageData(_imageData, []);
        setImageSelectionModal(false);
        setLanguage(null);
      })
      .catch(errorMessage => dispatch(toastMsg("error", errorMessage.message)));
  };

  const deleteImage = imageLanguage => {
    const path = [imageLanguage, "imageURL"];
    handleBannerImageData("", path);
  };

  const handeImageSelectionModal = language => {
    setLanguage(language);
    setImageSelectionModal(!imageSelectionModal);
  };

  const handleChangeEnabled = ({ name, value, language }) => {
    const enabledOn = get(image, `${language}.enabledOn`, []);

    enabledOn.indexOf(name) === -1 && value
      ? enabledOn.push(name)
      : enabledOn.splice(enabledOn.indexOf(name), 1);

    updateRowImageData({
      name: "enabledOn",
      value: enabledOn,
      language
    });
  };

  const showDeviceCheckboxes = useMemo(() => {
    if (!isDeviceCheckboxesRequired) return false;
    return deviceType !== deviceTypes.desktop;
  }, [deviceType, isDeviceCheckboxesRequired]);

  const handleSpacingChange = value => {
    updateRowImageData({
      name: "styles",
      value
    });
  };

  const handleAbsoluteStyleChange = (value, language) => {
    updateRowImageData({
      name: "absoluteStyles",
      value,
      language
    });
  };

  const handleChangeHoverStyles = (key, value, maxLength) => {
    if (value > maxLength) value = maxLength;
    else if (value < 0) value = 0;

    const _imageData = cloneDeep(image);
    value = +Number(value).toFixed(3);
    set(_imageData, ["styles", "hoverValues", key], value);
    let formattedValue;

    switch (key) {
      case "transform": {
        formattedValue = `scale(${value})`;
        break;
      }
      case "transition": {
        formattedValue = `transform ${value}s`;
        break;
      }

      default: {
        formattedValue = value;
      }
    }

    set(_imageData, ["styles", "hover", key], formattedValue);
    handleBannerImageData(_imageData, []);
  };

  const getRedirectionUrlList = async text => {
    setLoading(true);
    const response = await dispatch(fetchRedirectionUrlList(10, 0, text));
    let newList = response?.data?.data || [];
    newList = newList.map(({ url }) => url);
    setRedirectionUrlOptions([...new Set(newList)]);
    setLoading(false);
  };
  const opacity = get(styles, "hoverValues.opacity", 0);
  const transform = get(styles, "hoverValues.transform", 0);
  const transition = get(styles, "hoverValues.transition", 0);

  return (
    <div className="banner-image-modal">
      {imageSelectionModal && (
        <ImageSelectionModal
          title="Upload Image"
          modalName={"imageSelectionModal"}
          keyName="modal"
          language={language}
          containerName={"dynamicBanner"}
          onHide={() => setImageSelectionModal(false)}
          onSuccess={handleImageUploadSuccess}
          isOpen={imageSelectionModal}
          handleClose={() => setImageSelectionModal(false)}
          imageFormatArr={imageFormatArr}
          disableCDNuploadOption={false}
        />
      )}
      <div>
        <div className="banner-wrapper">
          <div className="banner-english-wrapper">
            <Autocomplete
              inputValue={get(image, `english.redirectionLink`, "")}
              onInputChange={(e, newInputValue) => {
                updateRowImageData({
                  name: "redirectionLink",
                  value: newInputValue || "",
                  language: "english"
                });
                if (newInputValue?.length && newInputValue.length % 3 === 0)
                  getRedirectionUrlList(newInputValue);
              }}
              loading={loading}
              freeSolo
              options={redirectionUrlOptions}
              sx={{ width: "100%" }}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Redirection Link(must start with '/')"
                  size="small"
                  required
                  lang="english"
                  InputLabelProps={{ shrink: true }}
                  placeholder={
                    SLIDER_IMAGE_ACCORDION_PLACEHOLDERS.english.redirectionLink
                  }
                />
              )}
              onBlur={() => {
                setRedirectionUrlOptions([]);
                setLoading(false);
              }}
            />
            {showDeviceCheckboxes && (
              <Box>
                {deviceCheckboxes.map(({ name, label }) => {
                  const enabledOn = get(image, `english.enabledOn`, []);
                  return (
                    <FormControlLabel
                      control={
                        <Checkbox
                          key={name}
                          name={name}
                          checked={enabledOn.includes(name)}
                          onChange={e =>
                            handleChangeEnabled({
                              name: e.target.name,
                              value: e.target.checked,
                              language: "english"
                            })
                          }
                        />
                      }
                      label={label}
                    />
                  );
                })}
              </Box>
            )}
            <UploadImageModal
              imageURL={get(image, `english.imageURL`, "")}
              language="english"
              handeImageSelectionModal={() =>
                handeImageSelectionModal("english")
              }
              deleteImage={deleteImage}
              infoIconTitle={IMAGE_SIZE_AND_TYPE_TOOLTIP}
            />
            {showCountdownStylingControls && (
              <Box sx={{ marginTop: "10px" }}>
                <CountdownStylingControls
                  styles={get(image, "english.absoluteStyles", {})}
                  handleSpacingChange={value =>
                    handleAbsoluteStyleChange(value, "english")
                  }
                  CommonStyle={{ display: "block", width: "100%" }}
                  positionStyle={{ width: "80%" }}
                />
              </Box>
            )}
          </div>
          <div className="banner-arabic-wrapper">
            <Autocomplete
              inputValue={get(image, `arabic.redirectionLink`, "")}
              onInputChange={(e, newInputValue) => {
                updateRowImageData({
                  name: "redirectionLink",
                  value: newInputValue || "",
                  language: "arabic"
                });
                if (newInputValue?.length && newInputValue.length % 3 === 0)
                  getRedirectionUrlList(newInputValue);
              }}
              loading={loading}
              freeSolo
              options={redirectionUrlOptions}
              sx={{ width: "100%" }}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Redirection Link(must start with '/')"
                  size="small"
                  required
                  lang="arabic"
                  InputLabelProps={{ shrink: true }}
                  placeholder={
                    SLIDER_IMAGE_ACCORDION_PLACEHOLDERS.arabic.redirectionLink
                  }
                />
              )}
              onBlur={() => {
                setRedirectionUrlOptions([]);
                setLoading(false);
              }}
            />
            {showDeviceCheckboxes && (
              <Box>
                {deviceCheckboxes.map(({ name, label }) => {
                  const enabledOn = get(image, `arabic.enabledOn`, []);
                  return (
                    <FormControlLabel
                      control={
                        <Checkbox
                          key={name}
                          name={name}
                          checked={enabledOn.includes(name)}
                          onChange={e =>
                            handleChangeEnabled({
                              name: e.target.name,
                              value: e.target.checked,
                              language: "arabic"
                            })
                          }
                        />
                      }
                      label={label}
                    />
                  );
                })}
              </Box>
            )}
            <UploadImageModal
              imageURL={get(image, `arabic.imageURL`, "")}
              language="arabic"
              handeImageSelectionModal={() =>
                handeImageSelectionModal("arabic")
              }
              deleteImage={deleteImage}
              infoIconTitle={IMAGE_SIZE_AND_TYPE_TOOLTIP}
            />
            {showCountdownStylingControls && (
              <Box sx={{ marginTop: "10px" }}>
                <CountdownStylingControls
                  styles={get(image, "arabic.absoluteStyles", {})}
                  handleSpacingChange={value =>
                    handleAbsoluteStyleChange(value, "arabic")
                  }
                  CommonStyle={{ display: "block", width: "100%" }}
                  positionStyle={{ width: "80%" }}
                />
              </Box>
            )}
          </div>
        </div>
        <Box sx={{ marginBottom: "10px", marginTop: "20px" }}>
          <TextField
            type="text"
            name={"bannerId"}
            value={image.bannerId || ""}
            onChange={e =>
              updateRowImageData({
                name: "bannerId",
                value: e.target.value || ""
              })
            }
            size="small"
            variant="outlined"
            label={"Banner Id"}
            placeholder="Please Enter Banner Id"
            InputLabelProps={{ shrink: true }}
            required
            sx={{ width: "49%" }}
          />
          <TextField
            type="text"
            name={"bannerTitle"}
            value={image.bannerTitle || ""}
            label={"Banner Title"}
            placeholder="Please Enter Banner Title"
            onChange={e =>
              updateRowImageData({
                name: "bannerTitle",
                value: e.target.value || ""
              })
            }
            size="small"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            required
            sx={{ width: "49%", float: "right" }}
          />
        </Box>
        <Box sx={{ marginTop: "10px" }}>
          <SpacingControls
            styles={styles}
            handleSpacingChange={handleSpacingChange}
            CommonStyle={{ display: "flex", width: "100%" }}
            paddingStyle={{ width: "70%" }}
            marginStyle={{ width: "30%", marginLeft: "20px" }}
          />
        </Box>
        {deviceType === deviceTypes.desktop &&
          bannerType !== bannerTypeValueConstants.SLIDER && (
            <Box sx={{ marginTop: "10px" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h6">Hover</Typography>
                <Tooltip
                  title={`Ideal values for Hover effect:
                        opacity: 1, transform: 1, transition: 0`}
                  placement="top"
                  arrow
                >
                  <InfoIcon fontSize="small" sx={{ marginLeft: "2px" }} />
                </Tooltip>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "10px"
                }}
              >
                <Typography sx={{ width: "10%" }}>Opacity</Typography>
                <Slider
                  value={opacity}
                  max={1}
                  min={0}
                  step={0.1}
                  onChange={(_, value) =>
                    handleChangeHoverStyles("opacity", value, 1)
                  }
                  size="medium"
                  sx={{ marginLeft: "10px", marginRight: "10px", width: "80%" }}
                />
                <TextField
                  type="Number"
                  name={"opacity"}
                  value={opacity}
                  onChange={e =>
                    handleChangeHoverStyles("opacity", e.target.value, 1)
                  }
                  size="small"
                  sx={{ width: "10%" }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "10px"
                }}
              >
                <Typography sx={{ width: "10%" }}>Transform</Typography>
                <Slider
                  value={transform}
                  max={2}
                  min={0}
                  step={0.025}
                  onChange={(_, value) =>
                    handleChangeHoverStyles("transform", value, 2)
                  }
                  sx={{ marginLeft: "10px", marginRight: "10px", width: "80%" }}
                />
                <TextField
                  type="Number"
                  name={"transform"}
                  value={transform}
                  onChange={e =>
                    handleChangeHoverStyles("transform", e.target.value, 2)
                  }
                  size="small"
                  sx={{ width: "10%" }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "10px"
                }}
              >
                <Typography sx={{ width: "10%" }}>Transition</Typography>
                <Slider
                  value={transition}
                  max={2}
                  min={0}
                  step={0.1}
                  onChange={(_, value) =>
                    handleChangeHoverStyles("transition", value, 2)
                  }
                  sx={{ marginLeft: "10px", marginRight: "10px", width: "80%" }}
                />
                <TextField
                  type="Number"
                  name={"transition"}
                  value={transition}
                  onChange={e =>
                    handleChangeHoverStyles("transition", e.target.value, 2)
                  }
                  size="small"
                  sx={{ width: "10%" }}
                />
              </Box>
            </Box>
          )}
      </div>
    </div>
  );
};
